import commoditiesService from 'features/settings/commodities/commodities.service';
import Event from 'common/event';
import { ActionWithDispatch } from 'common/actionWithDispatch';
import Commodity from 'domain/commodity';
import CommodityConfiguration from './commodityConfiguration'
import { trackApiCallNew } from "features/saveIndicator/trackApiCall";
import { Dispatch } from 'redux';

export class AllCommoditiesLoaded extends Event {
	constructor(public commodities: Commodity[]) {
		super(AllCommoditiesLoaded.EventName);
	}

	public static EventName: string = 'commodities/loaded';
}

export class MyCommoditiesLoaded extends Event {
	constructor(public commodities: Commodity[]) {
		super(MyCommoditiesLoaded.EventName);
	}

	public static EventName: string = 'myCommodities/loaded';
}

export class CommodityConfigurationLoaded extends Event {
	constructor(public commodityConfiguration: CommodityConfiguration) {
		super(CommodityConfigurationLoaded.EventName);
	}

	public static EventName: string = "commodityConfiguration/loaded";
}

export class CommodityUpdated extends Event {
	constructor(public commodity: Commodity) {
		super(CommodityUpdated.EventName);
	}

	public static EventName: string = "commodities/updated";
}

export class MyCommodityUpdated extends Event {
	constructor(public commodity: Commodity) {
		super(MyCommodityUpdated.EventName);
	}

	public static EventName: string = "myCommodities/updated";
}

export class MyCommodityUpdatedFromStream extends Event {
	constructor(public update: StreamingCommodityUpdate) {
		super(MyCommodityUpdatedFromStream.EventName);
	}

	public static EventName: string = "myCommodities/updatedFromStream";
}

export function loadAllCommodities(dispatch: Dispatch<any>): Promise<Commodity[]> {
	return commoditiesService
		.listCommodities(null, "all")
		.then((commodities) => dispatch(new AllCommoditiesLoaded(commodities).toObject()));
}

export function loadMyCommodities(dispatch: any): Promise<Commodity[]> {
	return commoditiesService
		.listCommodities(null, "mine")
		.then((commodities) => dispatch(new MyCommoditiesLoaded(commodities).toObject()));
}

//TODO: once everything is refactored to use the above approach, remove these
export function loadAllCommoditiesOld(commodityGroupFilter: string): ActionWithDispatch<Commodity[]> {
	return function (dispatch: any) {
		return commoditiesService
			.listCommodities(commodityGroupFilter, "all")
			.then((commodities) => dispatch(new AllCommoditiesLoaded(commodities).toObject()));
	};
}

export function loadMyCommoditiesOld(commodityGroupFilter?: string): ActionWithDispatch<Commodity[]> {
	return function (dispatch: any) {
		return commoditiesService
			.listCommodities(commodityGroupFilter, "mine")
			.then((commodities) => dispatch(new MyCommoditiesLoaded(commodities).toObject()));
	};
}

export function loadCommodityConfiguration(): ActionWithDispatch<CommodityConfiguration> {
	return function (dispatch: any) {
		return commoditiesService
			.getCommodityConfiguration()
			.then(data => dispatch(new CommodityConfigurationLoaded(data).toObject()));
	};
}

export function updateCommodity(dispatch: Dispatch<any>, commodity: Commodity): Promise<any> {
	return trackApiCallNew(dispatch, innerDispatch => {
		return commoditiesService
			.updateCommodity(commodity)
			.then(() => {
				innerDispatch(new MyCommodityUpdated(commodity).toObject());
				return innerDispatch(new CommodityUpdated(commodity).toObject())
			});
	});
}

export interface StreamingCommodityUpdate {
	id: number;
	priceProtection?: number;
}