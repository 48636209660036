import Commodity from 'domain/commodity';
import CommodityConfiguration from './commodityConfiguration';
import ThrowsValidationError from 'common/http/throwsValidationError';
import CommodityExchange from 'domain/commodityExchange';
import HttpService from 'common/http/httpService';

class CommodityService extends HttpService {
	async listCommodities(commodityGroupFilter: string, viewFilter: string): Promise<Commodity[]> {
		const response = await this.getClient().get<Commodity[]>("/settings/v1/commodities", {
			params: {
				results: viewFilter,
				commodityGroup: commodityGroupFilter
			}
		});
		return response.data;
	}
	async listMyCommodities(commodityGroupFilter?: string): Promise<Commodity[]> {
		const response = await this.getClient().get<Commodity[]>("/settings/v1/commodities", {
			params: {
				results: "mine",
				commodityGroup: commodityGroupFilter
			}
		});
		return response.data;
	}

	async getCommodityConfiguration(): Promise<CommodityConfiguration> {
		const response = await this.getClient().get<CommodityConfiguration>(
			"/settings/v1/configuration/commodities"
		);
		return response.data;
	}

	@ThrowsValidationError()
	async updateCommodity(commodity: Commodity): Promise<any> {
		await this.getClient().put(`/settings/v1/commodities/${commodity.id}`, commodity);
	}

	async listExchanges(): Promise<CommodityExchange[]> {
		const response = await this.getClient().get<CommodityExchange[]>(
			"settings/v1/exchanges"
		);
		return response.data;
	}
}

const commodityService = new CommodityService();
export default commodityService;
