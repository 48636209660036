import NP from "number-precision";
import Offer from "domain/offer"
import { OfferTypeEnum } from "domain/offerType"
import { OfferStatus } from "domain/offerStatus";
import Contract, { ContractType } from "domain/contract";
import { OrderType } from "domain/orderType";

export function calculateRequiredFuturesPrice(offer: Offer) {
    if (offer.orderType === OrderType.Tas && offer.status !== OfferStatus.Filled) {
        return offer.cashPrice;
    }

    switch(offer.type) {
        case OfferTypeEnum.Cash:
        case OfferTypeEnum.FixFuturesOnBasis:
        case OfferTypeEnum.FixBasisOnHta:
            if (offer.status === OfferStatus.Filled) {
                return NP.minus(offer.cashPrice, offer.basis ?? 0);
            }
            return NP.minus(offer.cashPrice ?? 0, offer.isBasisFixed ? (offer.basis ?? 0)  : (offer.currentBasis ?? 0));
        case OfferTypeEnum.Hta:
            return offer.cashPrice;
        default:
            return offer.cashPrice;
    }
}

export function calculateContractFuturesPrice(contract: Contract) {
    switch(contract.contractType) {
        case ContractType.Cash:
            return NP.minus(contract.cashPrice, contract.basis);
        case ContractType.Hta:
            return contract.cashPrice;
        case ContractType.Basis:
            return 0;
        default:
            return contract.cashPrice;
    }
}

export function calculateToHit(offer: Offer): number {    
    const requiredPrice = calculateRequiredFuturesPrice(offer);
    
    return offer.customerWillSell
        ? NP.minus(requiredPrice, offer.futuresContract.price)
        : NP.minus(offer.futuresContract.price, requiredPrice);
}