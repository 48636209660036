export default interface StoneHedgeNotification {
    id: string;
    type: string;
    title: string;
    message: string;
    level: NotificationType;
    link?: string;
    isRead: boolean;
    createdDate: Date;
}

export enum NotificationType {
    DisplayOnly = 0,
    Success = 1,
    Error = 2,
    Warning 
}