export enum DeliveryMethod {
    Delivered = 1,
    PickedUp = 2,
    OpenStorage = 3,
    DelayedPrice = 4,
    GrainBank = 5,
    WarehouseReceipts = 6,
    HouseReceipts = 7,
    ShortTermStorage = 8,
    FarmDirect = 9,
}


export const AllDeliveryMethods: DeliveryMethod[] = [
    DeliveryMethod.Delivered,
    DeliveryMethod.PickedUp,
    DeliveryMethod.OpenStorage,
    DeliveryMethod.DelayedPrice,
    DeliveryMethod.GrainBank,
    DeliveryMethod.WarehouseReceipts,
    DeliveryMethod.HouseReceipts,
    DeliveryMethod.ShortTermStorage,
    DeliveryMethod.FarmDirect,
]

export const ExcludedDeliveryMethodsForDPContracts: DeliveryMethod[] = [
    DeliveryMethod.Delivered,
    DeliveryMethod.PickedUp,
    DeliveryMethod.WarehouseReceipts,
    DeliveryMethod.HouseReceipts,
]

export function getDeliveryMethodText(method: DeliveryMethod): string {
    switch (method) {
        case DeliveryMethod.DelayedPrice:
            return "Delayed Price";
        case DeliveryMethod.Delivered:
            return "To Be Delivered";
        case DeliveryMethod.OpenStorage:
            return "Open Storage";
        case DeliveryMethod.PickedUp:
            return "Picked Up";
        case DeliveryMethod.GrainBank:
            return "Grain Bank";
        case DeliveryMethod.WarehouseReceipts:
            return "Warehouse Receipts";
        case DeliveryMethod.HouseReceipts:
            return "House Receipts";
        case DeliveryMethod.ShortTermStorage:
            return "Short Term Storage";
        case DeliveryMethod.FarmDirect:
            return "Farm Direct";
        default:
            return "";
    }
}