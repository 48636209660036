import { ContractType } from "./contract";

export enum OfferTypeEnum
{
    Cash = 1,
    Hta = 2,
    FixBasisOnHta = 3,
    FixFuturesOnBasis = 4,
    BasisContract = 5,
    DelayedPriceContract = 6
}

export const AllOfferTypeEnums: OfferTypeEnum[] = [
    OfferTypeEnum.Cash,
    OfferTypeEnum.Hta,
    OfferTypeEnum.BasisContract,
    OfferTypeEnum.DelayedPriceContract
]

export const OfferAndPricingTypes: OfferTypeEnum[] = [
    OfferTypeEnum.Cash,
    OfferTypeEnum.Hta,
    OfferTypeEnum.BasisContract,
    OfferTypeEnum.FixBasisOnHta,
    OfferTypeEnum.FixFuturesOnBasis,
    OfferTypeEnum.DelayedPriceContract
]

export function getOfferTypeName(offerType: OfferTypeEnum): string {
    switch (offerType) {
        case OfferTypeEnum.Cash:
            return "Cash";
        case OfferTypeEnum.Hta:
            return "HTA";
        case OfferTypeEnum.BasisContract:
            return "Basis Contract";
        case OfferTypeEnum.FixFuturesOnBasis:
            return "Set Futures";
        case OfferTypeEnum.FixBasisOnHta:
            return "Set Basis";
        case OfferTypeEnum.DelayedPriceContract:
            return "Delayed Price Contract";
        default:
            return "???";
    }
}

export function getMobileOfferTypeName(offerType: OfferTypeEnum): string {
    switch (offerType) {
        case OfferTypeEnum.Cash:
            return "C";
        case OfferTypeEnum.Hta:
            return "H";
        case OfferTypeEnum.BasisContract:
            return "B";
        case OfferTypeEnum.FixFuturesOnBasis:
            return "SF";
        case OfferTypeEnum.FixBasisOnHta:
            return "SB";
        case OfferTypeEnum.DelayedPriceContract:
            return "DP";
        default:
            return "???";
    }
}

export function fromContractType(contractType: ContractType): OfferTypeEnum {
    switch (contractType) {
        case ContractType.Cash:
            return OfferTypeEnum.Cash;
        case ContractType.Basis:
            return OfferTypeEnum.BasisContract;
        case ContractType.Hta:
            return OfferTypeEnum.Hta;
        case ContractType.DelayedPrice:
            return OfferTypeEnum.DelayedPriceContract;
        default:
            throw new Error(`Unrecognized contract type ${contractType}`);
    }
}

export function toContractType(offerType: OfferTypeEnum): ContractType {
    switch (offerType)
    {
        case OfferTypeEnum.Cash:
        case OfferTypeEnum.FixBasisOnHta:
        case OfferTypeEnum.FixFuturesOnBasis:
            return ContractType.Cash;
        case OfferTypeEnum.Hta:
            return ContractType.Hta;
        case OfferTypeEnum.BasisContract:
            return ContractType.Basis;
        case OfferTypeEnum.DelayedPriceContract:
            return ContractType.DelayedPrice;
        default:
            throw new Error(`Unrecognized offer type ${offerType}`);
    }
}

export default class OfferType
{
     id: number = 0;
     name: string = "";
}

export const AllOfferTypes: OfferType[] = AllOfferTypeEnums.map(id => ({ id, name: getOfferTypeName(id)}));
export const OfferTypesWithoutDelayedPrice: OfferType[] = AllOfferTypeEnums.filter(x => x !== OfferTypeEnum.DelayedPriceContract).map(id => ({ id, name: getOfferTypeName(id) }));