import React from "react";
import OfferColumnConfigurations from "./offerColumnConfigurations";
import ConfigurableColumnBuilder from "common/agGrid/configurableColumnBuilder";
import Offer from "domain/offer";
import { getMobileOfferTypeName, getOfferTypeName, OfferTypeEnum } from "domain/offerType";
import { calculateRequiredFuturesPrice, calculateToHit } from "./priceCalculator";
import { getOrderTypeName, OrderType } from "domain/orderType";
import { formatDecimalPlace, formatOrderDisplayId, formatPricingValue } from "common/formatters";
import { getDeliveryMethodText } from "domain/deliveryMethod";
import { getProducerDisplayName, Producer } from "features/settings/producers/producer";
import { canShowOrderId, getOrderStatusDisplayName } from "./orderStatus";
import SiteLink from "common/ui/SiteLink";
import { getDeliveryPeriodDisplayName } from "domain/deliveryPeriod";
import { OfferStatus } from "domain/offerStatus";
import { compareDisplayIds } from "common/strings";
import { getPaymentOptionName } from "domain/paymentOption";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getTotalFees } from "./helpers";
import { getProducerAccountDisplayName } from "features/settings/producers/producerAccount";

export class OfferColumn {
    //These strings should never change after publishing.
    //Otherwise it will invalidate user settings
    public static id: string = "id";
    public static customer: string = "customer";
    public static commodity: string = "commodity";
    public static position: string = "position";
    public static location: string = "location";
    public static contractLocation: string = "contractLocation";
    public static customerWillSell: string = "customerWillSell";
    public static offerType: string = "offerType";
    public static orderType: string = "orderType";
    public static hedgeType: string = "hedgeType";
    public static hedgeStatus: string = "hedgeStatus";
    public static quantity: string = "quantity";
    public static cashPrice: string = "cashPrice";
    public static finalPrice: string = "finalPrice";
    public static basis: string = "basis";
    public static futuresPrice: string = "futuresPrice";
    public static myBasis: string = "myBasis";
    public static contractSymbol: string = "contractSymbol";
    public static toHit: string = "toHit";
    public static offerExpiration: string = "offerExpiration";
    public static trader: string = "trader";
    public static lastUpdatedDate: string = "lastUpdatedDate";
    public static lastUpdatedBy: string = "lastUpdatedBy";
    public static comments: string = "comments";
    public static activationTime: string = "activationTime";
    public static cancelledDate: string = "cancelledDate";
    public static fcmAccount: string = "fcmAccount";
    public static fillQuantity: string = "fillQuantity";
    public static fillPrice: string = "fillPrice";
    public static fillTime: string = "fillTime";
    public static producerAccount: string = "producerAccount";
    public static totalFee: string = "totalFee";
    public static deliveryPeriod: string = "deliveryPeriod";
    public static deliveryStart: string = "deliveryStart";
    public static deliveryEnd: string = "deliveryEnd";
    public static deliveryMethod: string = "deliveryMethod";
    public static freightFee: string = "freightFee";
    public static createdDate: string = "createdDate";
    public static createdBy: string = "createdBy";
    public static orderId: string = "orderId";
    public static fcmAccountNumber: string = "fcmAccountNumber";
    public static paymentOption: string = "paymentOption";

    //Mobile-specific columns
    public static offerType_mobile: string = "offerType_mobile";
    public static price_mobile: string = "price_mobile";
}

function getCashPrice(offer: Offer): number {

    if (offer?.type === OfferTypeEnum.Hta || offer?.orderType === OrderType.Tas) return null;
    
    return offer?.cashPrice
}

function getHType(offer: Offer): string {
    return getOrderTypeName(offer.orderType === OrderType.Tas ? offer.orderType : offer?.order?.type);
}

function getPrice(offer: Offer): number {
    switch (offer.type) {
        case OfferTypeEnum.Cash:
            case OfferTypeEnum.FixBasisOnHta:
            return offer.cashPrice;
        case OfferTypeEnum.Hta:
        case OfferTypeEnum.FixFuturesOnBasis:
            return calculateRequiredFuturesPrice(offer);
        default:
            throw new Error("Unrecognized offer type");
    }
}

interface OfferIdComponentProps {
    offer: Offer;
}

const OfferIdComponent: React.FC<OfferIdComponentProps> = props => {

    if (props.offer == null) return null;

    const isFilled = props.offer.status === OfferStatus.Filled;

    return <span>
        {isFilled && (!props.offer.contractSyncFailureReason ?
            <SiteLink.ContractDetails contractDisplayId={props.offer.displayId} /> :
            <>{props.offer.displayId}</>)
        }
        {!isFilled &&
            <SiteLink.OfferDetails offerDisplayId={props.offer.displayId} />
        }
        {props.offer.order?.riskRejectionReason && <OverlayTrigger placement="auto" trigger="click" rootClose
            overlay={<Tooltip id={"riskrej_" + props.offer.id}>
                <p>Unable to hedge offer - {props.offer.order.riskRejectionReason}.</p>
                <p>You can either take action on this offer or dismiss this alert.</p>
                {!isFilled && <p><strong>This offer filled after the order was rejected. Please check your position before rehedging</strong></p>}
            </Tooltip>}>
            <i className="clickable pl-1 text-danger fa fa-exclamation-triangle"></i>
            </OverlayTrigger>
        }
        {props.offer.order?.rejectionMessage && <OverlayTrigger placement="auto" trigger="click" rootClose
            overlay={<Tooltip id={"orderRej_" + props.offer.id}>
                <p>{props.offer.order?.rejectionMessage}</p>
                </Tooltip>}>
            <i className="clickable pl-1 text-danger fa fa-exclamation-triangle"></i>
            </OverlayTrigger>
        }
        {props.offer.contractSyncFailureReason && <OverlayTrigger placement="auto" trigger="click" rootClose
            overlay={<Tooltip id={"contractRej_" + props.offer.id}>
                <p>Contract could not be created: {props.offer.contractSyncFailureReason}</p>
                </Tooltip>}>
            <i className="clickable pl-1 text-danger fa fa-exclamation-triangle"></i>
            </OverlayTrigger>
        }
    </span>;
}

export function getColumnBuilder(offerStatus: OfferStatus, isCustomerEditAllowed: boolean, onCustomerSelected: any, 
    isPaymentOptionHidden: boolean, isCustomer: boolean, isMobile: boolean, displayContractLocation: boolean): ConfigurableColumnBuilder {

    const builder = new ConfigurableColumnBuilder();
    const defaultClass = isMobile ? "mobile": "";
    //Default columns
    builder.for(OfferColumn.id, "ID#", null, "Offer ID")
        .withGetter(m => m)
        .withFilterGetter(m => m.displayId)
        .withCellRenderer<Offer>(params => <OfferIdComponent offer={params.node.data} />)
        //refresh the column if the alert is acknowledged
        .withEquals((o1, o2) => {
            return o1.data?.displayId === o2.data?.displayId && o1.data?.order?.riskRejectionReason === o2.data?.order?.riskRejectionReason
        })
        .withComparator((o1, o2) => compareDisplayIds(o1.data?.displayId, o2.data?.displayId))
        .withColumnClass(defaultClass, defaultClass)
        .withWidth(isMobile ? 55: 95);
    builder.for(OfferColumn.customer, "Customer", "", "", { hide: isCustomer })
        .withGetter(params => getProducerDisplayName(params.data?.producer))
        .withCellRenderer<Offer>(params => {
            const producer: Producer = params.node.data?.producer;
            
            if(!isCustomerEditAllowed) 
                return <span>{getProducerDisplayName(producer)}</span>

            else if(!producer.isActive)
                return <span style={{color: "gray" }}>{getProducerDisplayName(producer)}</span>

            return <button className="link-button" onClick={() => onCustomerSelected(producer)} >
                {getProducerDisplayName(producer)}
            </button>
        })
        .withWidth(150);
    builder.for(OfferColumn.commodity, "Commodity", "deliveryPeriod.commodity.name")
        .withWidth(100);
    builder.for(OfferColumn.position, "Position", "positionSetting.displayName", "Position", { hide: isCustomer })
        .withWidth(80);
    builder.for(OfferColumn.location, "Location", "location.name", "Inventory Location")
        .withFormatter(m => m ?? "Determine Later")
        .withWidth(150);
    builder.for(OfferColumn.contractLocation, "Cont Loc", "contractLocation.name", "Contract Location", { hide: !displayContractLocation })
        .withFormatter(m => m ?? "Determine Later")
        .withWidth(150);
    builder.for(OfferColumn.customerWillSell, "B/S", "customerWillSell", "Buy or Sell")
        .withFormatter(val => val ? "Sell" : "Buy")
        .withWidth(60);
    if (isMobile) {
        builder.for(OfferColumn.offerType_mobile, "Type", "type", "Offer Type")
            .withFormatter(getMobileOfferTypeName)
            .withColumnClass(defaultClass, defaultClass)
            .withWidth(40);
    }
    else {
        builder.for(OfferColumn.offerType, "Type", "type", "Offer Type")
            .withFormatter(getOfferTypeName)
            .withWidth(70);    
    }
    builder.for(OfferColumn.orderType, "Ord Type", "orderType", "Order Type")
        .withFormatter(getOrderTypeName)
        .withWidth(80);
    builder.for(OfferColumn.hedgeType, "H Type", "order.type", "Hedge Order Type", { hide: isCustomer })
        .withGetter(params => getHType(params.node.data))
        .withWidth(80);
    builder.for(OfferColumn.hedgeStatus, "H Status", "order.status", "Hedge Order Status", { hide: isCustomer })
        .withGetter(params => getOrderStatusDisplayName(params.data?.order?.status))
        .withWidth(80);
    builder.for(OfferColumn.quantity, "QTY", "quantity", "Offer Quantity", { aggFunc: "npSum"})
        .asNumber()
        .withColumnClass(defaultClass, defaultClass)
        .withWidth(isMobile ? 50: 60);
    builder.for(OfferColumn.cashPrice, "Cash", "cashPrice", "Offer Cash Price")
        .withGetter(params => getCashPrice(params.node.data))
        .withFormatter(formatPricingValue)
        .asRightAligned()
        .withWidth(80);
    builder.for(OfferColumn.finalPrice, "Final Price", "finalPrice", "Offer Final Price")
        .withFormatter(formatPricingValue)
        .asRightAligned()
        .withWidth(80);
    if (isMobile) {
        builder.for(OfferColumn.price_mobile, "Price", "", "")
            .withGetter(params => getPrice(params.node.data))
            .withFormatter(formatPricingValue)
            .withColumnClass(defaultClass, defaultClass)
            .asRightAligned()
            .withWidth(55);
    }
    builder.for(OfferColumn.basis, "Act. Basis", "basis", "Actual Basis")
        .asRightAligned()
        .withCellRenderer<Offer>(params => {
            const basis = params.node.data.basis == null ? "--" : formatDecimalPlace(params.node.data.basis);
            return <span>{params.node.data.isBasisFixed && <i className="material-icons lock-icon">lock</i>}{basis}</span>
        })
        .withWidth(80);
    builder.for(OfferColumn.futuresPrice, "Futures", null, "Req. Futures Price")
        .withGetter(params => params.data == null ? null : calculateRequiredFuturesPrice(params.data))
        .asDecimal()
        .withWidth(80);
    builder.for(OfferColumn.myBasis, "P. Basis", "currentBasis", "Posted Basis")
        .withGetter(params => params.node.data.type === OfferTypeEnum.Hta ? null : params.node.data.currentBasis)
        .withFormatter(formatPricingValue)
        .withColumnClass(["pr-4", "ag-right-aligned-cell"], ["pr-4", "ag-right-aligned-header"])
        .withWidth(100);
    builder.for(OfferColumn.contractSymbol, "Sym", "futuresContract.symbol", "Futures Symbol")
        .withColumnClass(defaultClass, defaultClass)
        .withWidth(isMobile ? 50: 65);
    if (offerStatus === OfferStatus.Working || offerStatus === OfferStatus.Pending) {
        builder.for(OfferColumn.toHit, "To Hit", "", "Diff. of Offer & Mkt")
            .withGetter(params => {
                if (params.data == null) return null;
                if (params.node.data.orderType === OrderType.Tas) return null;
                return calculateToHit(params.data);
            })
            .asDecimal()
            .withColumnClass(["pr-3", "ag-right-aligned-cell"], ["pr-3", "ag-right-aligned-header"])
            .withWidth(isMobile ? 78 : 100);
    }

    
    builder.for(OfferColumn.offerExpiration, "Expiration", "expirationDate", "Expiry of Offer & Order")
        .asUtcDate()
        .withWidth(70);
    builder.for(OfferColumn.trader, "Trader", "trader.lastName")
        .withWidth(100);
    builder.for(OfferColumn.createdDate, "Created Date", "createdDate", "Created Date")
        .asDateTime()
        .withWidth(150);
    builder.for(OfferColumn.lastUpdatedDate, "Last Update", "lastUpdatedDate", "Time Stamp")
        .asDateTime()
        .withWidth(150);


    if (offerStatus === OfferStatus.Cancelled) {
        builder.for(OfferColumn.cancelledDate, "CXL T", "order.cancelationTime", "Cancel time of order")
            .asDateTime();
    }

    //extra columns
    builder.for(OfferColumn.comments, "Cmnt", "comments", "Comment")
        .withWidth(150);
    builder.for(OfferColumn.lastUpdatedBy, "Modified By", "lastUpdatedBy.name", "");
    builder.for(OfferColumn.fillPrice, "Fill P", "order.fillPrice", "Order Fill Price", { hide: isCustomer })
        .asDecimal()
        .withWidth(80);
    builder.for(OfferColumn.fillQuantity, "FLD", "order.fillQuantity", "Order Filled Size", { hide: isCustomer })
        .asNumber()
        .withWidth(80);
   builder.for(OfferColumn.fillTime, "Fill T", "fillTime", "Offer Fill Time", { hide: isCustomer })
        .asDateTime()
        .withWidth(150);
    builder.for(OfferColumn.producerAccount, "Acct ID", "", "External Acct ID")
        .withGetter(params => getProducerAccountDisplayName(params.data?.customerAccount))
        .withWidth(160);
    builder.for(OfferColumn.totalFee, "Fee", "", "Total Fees")
        .withGetter(params => getTotalFees(params.node.data))
        .asDecimal()
        .withColumnClass(["pr-2", "ag-right-aligned-cell"], ["pr-2", "ag-right-aligned-header"])
        .withWidth(60);
    builder.for(OfferColumn.deliveryPeriod, "Del Period", "deliveryPeriod.name", "Delivery Period")
        .withGetter(params => getDeliveryPeriodDisplayName(params.data?.deliveryPeriod))
        .withWidth(195);
    builder.for(OfferColumn.deliveryStart, "Del Start", "deliveryPeriod.start", "Delivery Start")
        .asUtcDate()
        .withWidth(90);
    builder.for(OfferColumn.deliveryEnd, "Del End", "deliveryPeriod.end", "Delivery End")
        .asUtcDate()
        .withWidth(90);
    builder.for(OfferColumn.deliveryMethod, "Del Method", "deliveryMethod", "Delivery Method")
        .withGetter(params => getDeliveryMethodText(params.data?.deliveryMethod))
        .withWidth(90);
    builder.for(OfferColumn.freightFee, "Freight Fee", "freightFee", "Freight Fee")
        .withFormatter(formatPricingValue)
        .asRightAligned()
        .withWidth(80);
    builder.for(OfferColumn.orderId, "Order#", "order.displayId", "Hedge Order ID#", { hide: isCustomer})
        .withGetter(params => canShowOrderId(params.data?.order?.status) ? params.data?.order?.displayId : "")
        .withFormatter(formatOrderDisplayId)
        .withTooltipValueGetter(params => canShowOrderId(params.data?.order?.status) ? params.data?.order?.displayId : "")
        .withWidth(90);
    builder.for(OfferColumn.fcmAccountNumber, "FCM Acct#", "order.fcmAccountNumber", "FCM Acct#", { hide: isCustomer })
        .withWidth(90);
    builder.for(OfferColumn.createdBy, "Created By", "createdBy.name", "Created By")
        .withWidth(150);
    if(!isPaymentOptionHidden){
        builder.for(OfferColumn.paymentOption, "Payment Option", "paymentOption", "Payment Option")
            .withFormatter(getPaymentOptionName)
            .withWidth(120);
    }
    return builder;
}

export const defaultOfferColumns: OfferColumnConfigurations = {
    working: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
        OfferColumn.id,
        OfferColumn.orderId,
        OfferColumn.customer,
        OfferColumn.commodity,
        OfferColumn.position,
        OfferColumn.location,
        OfferColumn.contractLocation,
        OfferColumn.customerWillSell,
        OfferColumn.offerType,
        OfferColumn.hedgeType,
        OfferColumn.hedgeStatus,
        OfferColumn.quantity,
        OfferColumn.cashPrice,
        OfferColumn.futuresPrice,
        OfferColumn.myBasis,
        OfferColumn.contractSymbol,
        OfferColumn.toHit,
        OfferColumn.deliveryPeriod,
        OfferColumn.createdDate,
        OfferColumn.offerExpiration,
        OfferColumn.trader,
        OfferColumn.lastUpdatedDate
    ]},
    pending: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
        OfferColumn.id,
        OfferColumn.customer,
        OfferColumn.commodity,
        OfferColumn.position,
        OfferColumn.location,
        OfferColumn.contractLocation,
        OfferColumn.customerWillSell,
        OfferColumn.offerType,
        OfferColumn.orderType,
        OfferColumn.hedgeStatus,
        OfferColumn.quantity,
        OfferColumn.cashPrice,
        OfferColumn.futuresPrice,
        OfferColumn.myBasis,
        OfferColumn.contractSymbol,
        OfferColumn.toHit,
        OfferColumn.deliveryPeriod,
        OfferColumn.offerExpiration,
        OfferColumn.trader,
        OfferColumn.lastUpdatedDate
    ]},
    filled: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
        OfferColumn.id,
        OfferColumn.orderId,
        OfferColumn.comments,
        OfferColumn.customer,
        OfferColumn.commodity,
        OfferColumn.position,
        OfferColumn.location,
        OfferColumn.contractLocation,
        OfferColumn.customerWillSell,
        OfferColumn.offerType,
        OfferColumn.hedgeType,
        OfferColumn.quantity,
        OfferColumn.fillQuantity,
        OfferColumn.futuresPrice,
        OfferColumn.fillPrice,
        OfferColumn.basis,
        OfferColumn.contractSymbol,
        OfferColumn.cashPrice,
        OfferColumn.finalPrice,
        OfferColumn.deliveryPeriod,
        OfferColumn.trader,
        OfferColumn.lastUpdatedDate,
        OfferColumn.fillTime
    ]},
    cancelled: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
        OfferColumn.id,
        OfferColumn.customer,
        OfferColumn.commodity,
        OfferColumn.position,
        OfferColumn.location,
        OfferColumn.contractLocation,
        OfferColumn.customerWillSell,
        OfferColumn.offerType,
        OfferColumn.hedgeType,
        OfferColumn.hedgeStatus,
        OfferColumn.quantity,
        OfferColumn.cashPrice,
        OfferColumn.futuresPrice,
        OfferColumn.basis,
        OfferColumn.contractSymbol,
        OfferColumn.deliveryPeriod,
        OfferColumn.trader,
        OfferColumn.lastUpdatedDate,
        OfferColumn.cancelledDate,
        OfferColumn.comments
    ]},
    expired: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
        OfferColumn.id,
        OfferColumn.customer,
        OfferColumn.commodity,
        OfferColumn.position,
        OfferColumn.location,
        OfferColumn.contractLocation,
        OfferColumn.offerType,
        OfferColumn.hedgeType,
        OfferColumn.hedgeStatus,
        OfferColumn.quantity,
        OfferColumn.cashPrice,
        OfferColumn.futuresPrice,
        OfferColumn.basis,
        OfferColumn.contractSymbol,
        OfferColumn.deliveryPeriod,
        OfferColumn.offerExpiration,
        OfferColumn.trader,
        OfferColumn.lastUpdatedDate
    ]}
}

export const mobileColumns: OfferColumnConfigurations = {
    working: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
        OfferColumn.id,
        OfferColumn.customer,
        OfferColumn.offerType_mobile,
        OfferColumn.quantity,
        OfferColumn.contractSymbol,
        OfferColumn.price_mobile,
        OfferColumn.toHit
    ]},
    pending: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            OfferColumn.id,
            OfferColumn.customer,
            OfferColumn.commodity,
            OfferColumn.offerType,
            OfferColumn.quantity,
            OfferColumn.contractSymbol,
            OfferColumn.price_mobile,
            OfferColumn.toHit
    ]},
    filled: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            OfferColumn.id,
            OfferColumn.customer,
            OfferColumn.commodity,
            OfferColumn.offerType,
            OfferColumn.quantity,
            OfferColumn.contractSymbol,
            OfferColumn.price_mobile
    ]},
    cancelled: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            OfferColumn.id,
            OfferColumn.customer,
            OfferColumn.commodity,
            OfferColumn.offerType,
            OfferColumn.quantity,
            OfferColumn.contractSymbol,
            OfferColumn.price_mobile
    ]},
    expired: {
        preferredSortColumnId: null,
        preferredSortOrder: null,
        visibleColumns: [
            OfferColumn.id,
            OfferColumn.customer,
            OfferColumn.commodity,
            OfferColumn.offerType,
            OfferColumn.quantity,
            OfferColumn.contractSymbol,
            OfferColumn.price_mobile
    ]}
}