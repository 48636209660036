import toastr from "toastr";
import "toastr/build/toastr.min.css";

export interface INotificationService {
    success(message: string, title?: string): void;
    error(message: string, title?: string, closable?: boolean): void;
    info(message: string, title?: string): void;
    warning(message: string, title?: string): void;
}

class NotificationService implements INotificationService {

    toastrConfig = { preventDuplicates: true, positionClass: 'toast-bottom-right'};

    success(message: string, title?: string) {
        toastr.success(message, title, this.toastrConfig);
    }
    
    error(message: string, title?: string, closable?: boolean) {
        if (closable)
            toastr.error(message, title, {...this.toastrConfig,  closeButton: true, timeOut: 0, extendedTimeOut: 0});
        else
            toastr.error(message, title);
    }

    info(message: string, title?: string) {
        toastr.info(message, title, this.toastrConfig);
    }

    warning(message: string, title?: string) {
        toastr.warning(message, title, this.toastrConfig);
    }
}

const notificationService: INotificationService = new NotificationService();

export default notificationService;