import Commodity from "domain/commodity";
import Contract from "domain/contract";
import Offer from "domain/offer";
import { isAllowed, OfferStatus } from "domain/offerStatus";
import { Offering } from "features/settings/offerings/offering";
import { isPartiallyFilled } from "./orderStatus";
import AddOfferModel from "./addOfferModel";
import { OfferTypeEnum } from "domain/offerType";
import { getLastMonthEndDate, getNthLastWeekDay } from "common/formatters";
import { sumArray } from "common/decimal";
import { DeliveryMethod } from "domain/deliveryMethod";
import { Trader } from "features/settings/traders/trader";
import * as NP from "number-precision";
import { OrderType } from "domain/orderType";
import FuturesContract from "domain/futuresContract";
import { UserConfiguration } from "features/login/loggedInUser";
import { isFilledOrPartiallyFilled} from "./orderStatus";

export function allowEdit(offer: Offer, isCustomer: boolean, userConfiguration: UserConfiguration, offerings: Offering[], trader: Trader): boolean {
    if (!userConfiguration.canMakeOffer) {
        return false;
    }
    if ((offer.customerWillSell && !userConfiguration.canSell) ||
        (!offer.customerWillSell && !userConfiguration.canBuy)) {
        return false;
    }

    if (isCustomer && offer.status === OfferStatus.Pending) {
        return false;
    }

    if (offer.location != null && trader != null && !trader.locations.some(l => l.id === offer.location.id)) return false;

    if (offer.orderType === OrderType.Tas && isFilledOrPartiallyFilled(offer.order?.status)) {
        return false;
    }

    const isPartialFill = isPartiallyFilled(offer.order?.status);
    if (!isPartialFill && isAllowed(offer, OfferStatus.Working, OfferStatus.Pending)
        && (!isCustomer ||
            (offerings.some(o => o.location.id === offer.location?.id && o.commodity.id === offer.deliveryPeriod.commodity.id && o.offerTypes.includes(offer.type)))
        )
    ) {
        return true;
    }
    return false;
}

export function getModelForSetFutures(contract: Contract): AddOfferModel {
    return {
        contractId: contract.internalId,
        displayId: contract.displayId,
        offerType: OfferTypeEnum.FixFuturesOnBasis,
        commodity: contract.commodity,
        deliveryMethod: contract.deliveryMethod,
        deliveryPeriod: contract.deliveryPeriod,
        futuresContract: contract.futuresContract,
        expirationDate: contract.expirationDate,
        location: contract.location,
        region: contract.region,
        contractLocation: contract.contractLocation,
        customer: contract.producer,
        customerAccount: contract.customerAccount,
        customerWillSell: contract.customerWillSell,
        quantity: contract.quantityContracted,
        basis: contract.basis,
        isBasisFixed: contract.isBasisLocked,
        paymentOption: contract.paymentOption,
        deferredPayDate: contract.deferredPayDate,
        fees: contract.fees,
        freightFee: contract.freightFee,
        rollFee: contract.rollFee,
        customFields: contract.customFields
    };
}

export function getModelForSetBasis(contract: Contract): AddOfferModel {
    return {
        contractId: contract.internalId,
        displayId: contract.displayId,
        offerType: OfferTypeEnum.FixBasisOnHta,
        commodity: contract.commodity,
        deliveryMethod: contract.deliveryMethod,
        deliveryPeriod: contract.deliveryPeriod,
        futuresContract: contract.futuresContract,
        location: contract.location,
        contractLocation: contract.contractLocation,
        region: contract.region,
        customer: contract.producer,
        customerAccount: contract.customerAccount,
        customerWillSell: contract.customerWillSell,
        quantity: contract.quantityContracted,
        futuresPrice: contract.futuresPrice,
        basis: contract.offer.currentBasis,
        isBasisFixed: false,
        paymentOption: contract.paymentOption,
        deferredPayDate: contract.deferredPayDate,
        expirationDate: contract.expirationDate,
        htaFee: contract.htaFee,
        freightFee: contract.freightFee,
        fees: contract.fees,
        rollFee: contract.rollFee,
        customFields: contract.customFields
    };
}

export function getModelForDelayedPrice(contract: Contract): AddOfferModel {
    return {
        contractId: contract.internalId,
        displayId: contract.displayId,
        offerType: OfferTypeEnum.Cash,
        commodity: contract.commodity,
        location: contract.location,
        contractLocation: contract.contractLocation,
        region: contract.region,
        customer: contract.producer,
        customerAccount: contract.customerAccount,
        customerWillSell: contract.customerWillSell,
        quantity: contract.quantityContracted,
        remainingQuantity: contract.remainingQuantity,
        paymentOption: contract.paymentOption,
        deferredPayDate: contract.deferredPayDate,
        fees: contract.fees,
        customFields: contract.customFields
    };
}

export function isCashOfferWithOpenStorage(offer: Offer) {
    return offer.type === OfferTypeEnum.Cash && offer.deliveryMethod === DeliveryMethod.OpenStorage;
}

export function getSecondLastBusinessDayOfLastMonth(date: Date): Date {
    const endOfLastMonth = getLastMonthEndDate(date);
    return getNthLastWeekDay(endOfLastMonth, 2);
}

export function getRemainingQuantityLabel(offerType: number, deliveryMethod: number): string {
    if ([OfferTypeEnum.FixBasisOnHta, OfferTypeEnum.FixFuturesOnBasis].includes(offerType)
        || deliveryMethod == DeliveryMethod.DelayedPrice) {
        return 'Unpriced Quantity'
    }
    else {
        return 'Remaining Quantity'
    }
}

export function getTotalFees(offer: Offer) {
    const extendedFeesSum = sumArray(offer.fees?.map(m => m.value ?? 0));
    const totalFees = NP.plus(offer.htaFee ?? 0, extendedFeesSum, offer.freightFee ?? 0, offer.rollFee ?? 0);
    return totalFees;
}

export function isMarketClosedForMarketOrder(orderType: OrderType, futuresContract: FuturesContract) {
    return orderType == OrderType.Market && (futuresContract?.isTradingHalted || futuresContract?.isMarketClosed);
}